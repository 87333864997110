import React from 'react';
import './style.scss';

function CategoryPageHeader({ title, subtitle }) {
  return (
    <div className="category-page-header-wrapper">
      <h1 className="category-page-subtitle">{title}에 {subtitle}</h1>
    </div>
  );
}

export default CategoryPageHeader;
